import * as React from 'react'
import classNames from 'classnames'

import styles from './Title.module.scss'

export default function Title({children, className = ''}) {
  return (
    <div className={classNames(styles['this'], className)}>
      { children }
    </div>
  )
}
