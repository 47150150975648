import * as React from 'react'

import './AddWidget.scss'

export default function AddWidget({onChange, disabled, units}) {
  const [count, setCount] = React.useState(units || 0)
  
  const onCountChange = (_count) => {
    setCount(_count)
    onChange(_count)
  }
  return (
    // <div className="WidgetContainer">
    <>
      {
        disabled && (
          <div className="WidgetContainer">
            <div className="OnlyAdd disabled">
              <div className="Add disabled">Add</div>
              <div className="Plus Plus-Secondary disabled">+</div>
            </div>
          </div>
        )
      }
      {  
          !disabled && count === 0 && (
            <div className="WidgetContainer--only-add">
              <div className="OnlyAdd" onClick={() => onCountChange(count + 1)}>
                <div className="Add">Add</div>
                <div className="Plus Plus-Secondary">+</div>
              </div>
            </div>
        )
      }

      {
        !disabled && count > 0 && (
          <div className="WidgetContainer">
            <div className="OperationWrapper">
              <div className="Minus" onClick={() => onCountChange(count - 1)}>&minus;</div>
              <div className="Count">{count}</div>
              <div className="Plus" onClick={() => onCountChange(count + 1)}>+</div>
            </div>
          </div>
        )
      }
    </>
  )
}
