import * as React from 'react';
import PropTypes from 'prop-types'

export default class Shave extends React.Component {
  static defaultProps = {
    tag: 'p',
    character: '…',
    children: '',
  }
  
  state = {}
  
  componentDidMount() {
    this.setState({ trimmedText: this.props.children });
  }

  componentDidUpdate() {
    this.updateText();
  }

  updateText = () => {
    const { element, state: { trimmedText }, props: { maxHeight } } = this;
    
    // Stop the loop once the component reaches the desired height
    if (element.offsetHeight > maxHeight) {
      const lastSpace = trimmedText.lastIndexOf(' ');
      // Avoid to trim the last word
      if (lastSpace >= 0) {
        this.setState({ trimmedText: trimmedText.substr(0, lastSpace) });
      }
    }
  }
  
  render() {
    const { props: { children, character, tag }, state: { trimmedText } } = this;

    return React.createElement(
      tag,
      {
        ref: element => (this.element = element),
        title: children,
      },
      trimmedText,
      children !== trimmedText && character
    );
  }

  static propTypes = {
    maxHeight: PropTypes.number.isRequired,
    character: PropTypes.string,
    children: PropTypes.string,
  }
}