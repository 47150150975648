import * as React from 'react'
import classNames from 'classnames'

import styles from './Sticky.module.scss'

export default function Sticky({top, bottom, children, stick, className, ...props}) {
    return (
        <div style={{top, bottom}}
             className={classNames(styles['Sticky'], top && styles['top'], top && stick && styles['Sticky--stick'], bottom && styles['bottom'], className)} {...props}>
            {children}
        </div>
    )
}
