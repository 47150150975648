import React from 'react'
import classNames from 'classnames'

import useOnScreen from '@littlestores/common/hooks/useOnScreen'

import styles from './Layout.module.scss'

export default function Layout({className, visibilityChange, options = {}, children}) {
  const [ref, visible] = useOnScreen(options)
  React.useEffect(() => {
    if (visibilityChange) {
      visibilityChange(visible)
    }
  }, [visible])

  return (
    <div ref={ref} className={classNames(styles["this"], className)}>
      { children }
    </div>
  )
}
